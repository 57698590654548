.footer-box{
    text-align: center;
    margin: 10px;
    padding-top: 30px;
}
.create-btn {
    background-image: linear-gradient(#2a94e4e3, #0675c7);
    font-size: 11px;
    font-weight: 600;
    float: left;
    width: 35%;
    margin-left: 60px;
    height: 34px;
}
.cancle-btn {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
    width: 35%;
    margin-right: 60px;
    height: 34px;
    font-size: 11px;
    font-weight: 600;
}

.document-replace-btn {
    border-radius: 4px !important;
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
    margin-top: 20px;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    border: 1px solid;
    padding: 10px 20px;
}
.document-created-date {
    color: #4b5564;
    float: left;
    width: 75%;
}
.document-download-btn{
    position: absolute;
    right: 0;
    border-radius: 4px !important;
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    border: 1px solid;
    padding: 12px 20px;
}
.download-text-field{
    display: inline-block;
}
.document-upload-btn{
    position: absolute;
    right: 0;
    border-radius: 4px !important;
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    border: 1px solid;
    padding: 12px 20px;
    margin-top: 15px;
}
.document-hint-txt{
    font-size: 12px;
    font-style: italic;
    color: #c3c3c3;
    margin-top: 5px;
}
.file-uploader{
    margin-top: 10px;
}