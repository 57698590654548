.application-container.users-container {

	.ant-table-thead>tr>th,
	.ant-table-tbody>tr>td {
		padding: 10px 16px;
	}

	.text-width {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 90%;
	}

	.bullet {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: #15d215;
		top: 12px;
		position: relative;
	}

	.bullet.greens {
		background-color: #15d215;
	}

	.bullet.reds {
		background-color: #e64831;
	}

	.bullet.oranges {
		background-color: #ff9300;
	}

	.bullet.greys {
		background-color: #919191;
	}

	.thermal.bullet {
		height: 15px;
		width: 15px;
		top: 0;
		left: 20px;
	}

	.user-name {
		width: 100%;
		cursor: pointer;
	}
	.mode-icon{
		position: relative;
		top:-6px
	}

	.app-name {
		margin-left: 25px;
	}
}

.application-detail.user-detail {
	label {
		line-height: 1;
	}

	.pending-action {
		width: 25px;
		margin-left: 10px;
	}

	.user-status {
		color: #66c76e;
		font-size: 14px;
		margin-left: 15px;
		background-color: #f8f9fa;
		padding: 3px 8px;
	}

	.user-status.failed {
		color: #e74b41;
	}

	.tab-section .customer-info .form-textfield {
		margin-bottom: 15px;
	}

	.tab-section .customer-info .from-left-col {
		margin-bottom: 10px;
	}

	.verification {
		color: #e74b41;
	}

	.copy-icon.edit.overide {
		bottom: 53px;
	}

	.success-icon.position {
		top: 30px;
	}

	.success-icon.position.error {
		top: 15px;
	}

	.feild-desc {
		margin-bottom: 22px;
	}

	.tab-section .customer-info .btn-save {
		margin-top: 10px;
	}

	.ant-table-thead>tr>th:nth-child(1),
	.ant-table-tbody>tr>td:nth-child(1) {
		padding: 10px 16px;
	}

	.connection-container {
		padding: 0 30px;
	}

	.raw-json {
		padding: 20px 30px;

		.copy-icon.edit.overide {
			top: 50px;
			right: 37px;
		}

		.string-json {
			background-color: #fafbfc;
			padding: 20px;
		}
	}

	.user-endpoint .ant-table-thead>tr>th {
		width: 33%;
	}

	.user-history .ant-table-thead>tr>th {
		width: 21%;
	}

	.button-box {
		margin-top: 20px;
	}

	.clear-btn {
		border-radius: 4px !important;
		color: #40a9ff;
		background-color: #fff;
		border-color: #40a9ff;
		margin-right: 20px;
		font-weight: 600;
		font-size: 12px;
	}

	.cerate-group-btn {
		border-radius: 4px !important;
		color: #40a9ff;
		background-color: #fff;
		border-color: #40a9ff;
		margin-right: 20px;
		font-weight: 600;
		font-size: 11px;
		display: inline-block;
	}

	.clear-btn.ant-btn.disabled {
		color: rgba(0, 0, 0, 0.25);
		background-color: #f5f5f5;
		border-color: #d9d9d9;
	}

	.capture-image {
		width: 100%;
		height: 100%;
		background-color: #F2F6FA;
		padding: 15px;
		max-width: 225px;
	}

	.user-delete-btn {
		width: 36px;
		height: 36px;
		background: #F2F2F2;
		border-radius: 13px;
		display: inline-block;
		position: absolute;
		margin-top: -2px;
		cursor: pointer;
	}

	.user-delete-btn:hover {
		background: #f9eded;
	}

	.user-delete-btn.disabled {
		cursor: not-allowed;
		background: #F2F2F2;
		pointer-events: none;
	}

	.user-delete-button {
		-webkit-mask-image: url('../../../image/delete.svg');
		-webkit-mask-repeat: no-repeat;
		width: 14px;
		height: 19px;
		background-color: #E42A31;
		-webkit-mask-size: 14px;
		position: relative;
		left: 11px;
		top: 8px;
	}

	.delete-count {
		display: inline-block;
		position: relative;
		margin-left: 50px;
		color: #151515;
		font-size: 13px;
	}

	.face-user-right-containt {
		text-align: right;
		color: #2E394C;
		font-size: 17px;
	}

	.face-user-container {
		margin-top: 10px;
	}

	.face-online {
		height: 13px;
		width: 13px;
		border-radius: 50%;
		background-color: #00C264;
		position: absolute;
		top: 6px;
		left: 65px
	}

	.face-code {
		height: 17px;
		width: 25px;
		border-radius: 16%;
		background-color: #4B81A7;
		position: relative;
		top: 28px;
		left: -56px;
		display: inline-block;
		text-align: center;
		font-size: 13px;
		color: #ffffff;
	}

	.face-offline {
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background-color: #E42A31;
		position: absolute;
		top: 5px;
		right: 40px;
	}

	.face-background {
		position: relative;
	}

	.face-table .ant-table-column-has-actions>div {
		border-bottom: unset;
	}
}




.user-header-custom.customer-header {
	padding: 20px 120px 20px 30px !important;
}

.table-box.users {
	img {
		width: 80px;
		height: 80px;
		position: relative;
		bottom: 1px;
		cursor: pointer;
		object-fit: contain;
	}
}

.full-view-avatar {
	width: 420px;
	margin-bottom: 40px;
}

.user-image-header img {
	height: 100px;
	width: 100px;
}

.user-edit-icon {
	-webkit-mask-image: url('../../../image/edit-icon.svg');
	-webkit-mask-repeat: no-repeat;
	position: relative;
	height: 15px;
	width: 15px;
	background-color: #ffffff;
	-webkit-mask-size: 15px;
	right: 25px;
	top: 40px;
	display: inline-block;
}

.user-image-box {
	display: inline-block;
}

.transactions-filter {
	width: 80%
}

.transactions-filter-datepicker {
	min-width: unset !important;
}

.transactions-refresh-btn {
	border-radius: 4px !important;
	color: #fff;
	background-color: #40a9ff;
	border-color: #40a9ff;
	margin-right: 20px;
	font-weight: 600;
	font-size: 12px;
}

.transactions-clear-btn {
	border-radius: 4px !important;
	color: #40a9ff;
	background-color: #fff;
	border-color: #40a9ff;
	margin-right: 20px;
	font-weight: 600;
	font-size: 12px;
}

.transactions-app-id {
	color: #4b5564;
	margin-left: 4px;
	display: inline-block;
	float: left;
	margin-right: 10px;
}

.transactions-button-box {
	margin-top: 20px;
}

.transaction-detail-box {
	padding: 0px 30px;
}

.transaction-detail-first-txt {
	font-size: 16px;
	font-weight: 600;
	color: #151515;
	display: inline-block;
	position: relative;
	width: 20%;
	margin-right: 22px;
}

.act-label-width {
	width: 13%;
}

.transaction-detail-second-txt {
	font-size: 16px;
	color: #151515;
	display: inline-block;
}

.transaction-detail-containt {
	margin-top: 20px;
	margin-left: 22px;
}

.transaction-detail-image-heading {
	font-size: 16px;
	font-weight: 600;
	color: #151515;
	display: inline-block;
	position: relative;
}

.capture-image-box {
	width: 100%;
	align-self: inherit;
	min-height: 242px;
	box-shadow: 0px 2px 5px #535F6E33;
	margin: 20px 0;
	display: flex;
	background-color: #F2F6FA;
}

.capture-image {
	width: 100px;
	margin: auto;
}

.transaction-description-text {
	margin-top: 65px;
	box-shadow: 0px 2px 5px #535F6E33;
	margin-left: 20px;
	background-color: #FAFBFC;
	padding: 20px 50px;
}

.customer-name-status {
	color: #00C264;
	margin-left: 20px;
}

.group-detail .ant-tabs-nav {
	font-family: "nunito-Bold";
}

.activity-log-status {
	display: inline-flex;
}

.dropdown-level-txt {
	font-size: 15px;
	color: #151515;
}

.transction-header .select-input {
	width: 80% !important;
}

.select-input-multiple .ant-select-selector {
	min-height: 40px !important;
}

.txt-customer {
	color: #151515;
	margin-bottom: 10px !important;
}

.transactions-refresh-btn:hover,
.transactions-refresh-btn:focus,
.transactions-refresh-btn:active {
	background-color: #40a9ff;
	color: #fff;
	border-color: #40a9ff;
}

.transactions-clear-btn:hover,
.transactions-clear-btn:focus,
.transactions-clear-btn:active {
	background-color: #fff;
	color: #40a9ff;
	border-color: #40a9ff;
	background: #fff;
}

.template-save-btn {
	border-radius: 4px !important;
	color: #40a9ff;
	background: transparent linear-gradient(180deg, #249AF3 0%, #007DDC 100%) 0% 0% no-repeat padding-box;
	border-color: #40a9ff;
	font-weight: 600;
	font-size: 12px;
	display: inline-block;
	border: unset;
	color: #fff;
	padding: 10px 20px;
}

.template-save-btn:focus {
	border: unset;
}

.template-footer-btn {
	display: inline-block;
	position: relative;
	margin-top: 30px;
}


.export-box {

	.ant-calendar-picker {
		width: 100% !important;
	}

	.ant-calendar-picker-input.ant-input {
		line-height: 1.5;
		height: 40px;
	}

	.ant-calendar-picker-icon {
		top: 20px;
	}

	.from-left-col {
		margin-bottom: 4px;
		position: relative;
		top: -15px;
	}

	.label {
		display: inline-block;
		bottom: -8px;
		position: relative;
	}

	.txt-customer {
		color: #151515;
		margin-bottom: 2px !important;
		font-size: 13px;
	}

	.primary-field>input {
		height: 40px;
		font-size: 14px;
	}

	.ant-select-selection__rendered {
		min-height: 35px;
	}

	.export-input-box {
		padding: 5px 10px
	}

	.ant-select {
		margin-top: 0px !important;
	}
}

.export-footer {
	padding-top: 0px;
}


.slide-show-wrapper {
	.ant-modal {
		width: 80% !important;
		height: 100vh;
		top: 15px;

		.slide-header {
			text-align: center;

			div.flex {
				display: flex;
				justify-content: center;

				.mr_10 {
					margin-right: 10px;
				}
			}
		}

		.slid-show {
			min-height: 200px;
			margin: auto;

			.slide-images {
				margin: auto;
				max-height: 80vh;
				// height: 500px;
				// width: 100%;
			}

			.slide-images.success {
				border: 2px solid #4CAF50;
			}

			.slide-images.error {
				border: 2px solid #F44336;
			}
		}

		.ant-modal-body {
			padding: 20px;
			height: 93vh;
		}

		.slide-container {
			// display: flex;
		}

		.slider_navigation {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;

			.slide-paging {
				height: 35px;
				width: 35px;
				position: absolute;
				top: 50%;
				pointer-events: auto;
				cursor: pointer;
				transform: translateY(-50%);

				.anticon {
					font-size: 35px;
					color: #5FB0E5;
				}

				&.right-icon {
					right: 10px;
				}

				&.left-icon {
					left: 10px;
				}
			}

			.slide-paging:hover {
				cursor: pointer;

				.anticon {
					color: #3e85b3;
				}
			}
		}

		.ant-carousel .slick-dots li button {
			background: #5FB0E5;
			opacity: 0.4;
		}

		.ant-carousel .slick-dots li.slick-active button {
			background: #5FB0E5;
			opacity: 1
		}
	}

}

.transaction-id-field-box {
	padding: 10px;
	border: 1px solid #dbdbdb;
	width: 100%;
	outline: none;
	padding-left: 10px;
	border-radius: 4px;
}

.transaction-id-feild-desc {
	color: #2E394C;
	font: Italic 14px/0px Nunito;
	margin-bottom: 30px;
	margin-top: 10px;
	font-family: nunito-italic;
	font-size: 13px;
}

.transactions-filter .ant-input {
	height: 40px;
}

.collaps-box-container {
	margin: 10px 0;

	.ant-select-enabled {
		width: 100% !important;
	}

	.ant-collapse {
		background-color: unset;
		border: unset;
		color: #40a9ff;
	}

	.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		color: #40a9ff;
		width: max-content;
		margin-left: -14px;
	}

	.ant-collapse-content>.ant-collapse-content-box {
		padding: 4px;
	}

	.ant-collapse-content {
		border-top: unset;
	}

	.ant-collapse>.ant-collapse-item {
		border-bottom: unset;
	}

	.ant-select-selection {
		padding: 6px;
		height: 40px;
		margin-right: 10px;
	}
}

.select-input.ant-select.error>div {
	border: 1px solid red
}

.helper-text {
	color: #f44336;
	font-size: 0.80rem;
	margin-left: 14px;
	margin-right: 14px;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.66;
}

.activity-progress {
	width: 100%;
	height: 8px;
}

.app-image-caitainer {
	.app-icon {
		height: 82px;
		width: 82px;
		top: 1px;
		left: 1px;
		position: relative;
	}
}

.export-box {
	.ant-picker {
		height: 40px;
		width: 100%;
	}
}