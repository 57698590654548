integrations-container-width.application-detail.customer-detail .customer-header .customer-id {
	font-family: unset;
}

.application-detail.customer-detail .customer-header .customer-details {
	width: 75%;
}

.application-detail {
	.time-clock {
		border: 1px solid #e2e2e2;
		margin-left: 25px;
		padding: 2px;
		color: #2e394b;
		position: relative;
		bottom: 3px;
		font-size: 12px;
	}

	.app-type-icon{
		color: #fff;
		top: 26px;
    	left: 28px;
		position: absolute;
		font-weight: bold;
		font-size: 20px;
	}

	.customer-header .customer-details .customer-name {
		font-size: 25px;
	}
	.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
		position: relative;
		top: -10px;
		margin-right: 20px;
		display: inline-block;
		font-size: 12px;
		transform: translateY(50%);
		text-align: right;
	}
	.ant-collapse > .ant-collapse-item > .ant-collapse-header {
		position: relative;
		padding: 12px 16px;
		padding-left: 40px;
		color: #1890ff;
		line-height: 22px;
		cursor: pointer;
		transition: all 0.3s;
		text-align: right;
	}
	.ant-collapse-borderless > .ant-collapse-item {
		border-bottom: 1px solid #000000;
		position: relative;
		top: -11px;
		float: right;
		border: unset;
		width: 100%;
	}
	.ant-collapse-borderless {
		background-color: #fff;
		border: unset;
	}
	.integrations-tab-main-box {
		box-shadow: 0px 2px 5px #535f6e33;
		border-radius: 12px;
		padding: 40px;
	}

	.integrations-tab-padding {
		padding: 20px;
	}
	.integrations-icon {
		width: 80px !important;
		height: 80px !important;
	}
	.integrations-icon-middle {
		width: 100%;
		text-align: center;
		.endpoint-enabled-integrations-icon {
			margin-left: 0px;
		}
	}

	.integrations-tab-heading {
		text-align: center;
		font-size: 20px;
		letter-spacing: 0;
		color: #2e394c;
		font-weight: 600;
		opacity: 1;
		margin: 10px 0;
	}
	.remove-button-box {
		float: right;
		bottom: 0;
		margin-top: 35px;
	}
	.remove-button {
		font-size: 16px;
		float: right;
		border: unset;
		background-color: unset;
	}
	.feild-desc-subtext {
		color: #2e394c;
		margin-bottom: 30px;
		font-size: 16px;
		line-height: 20px;
		margin-top: 30px;
	}

	.feild-desc-add-button {
		float: right;
		color: #1991ec;
		font-weight: 600;
		margin-top: 12px;
		cursor: pointer;
	}
	.integrations-tab-sub-text {
		text-align: center;
		font-size: 16px;
		letter-spacing: 0;
		color: #2e394c;
		opacity: 1;
		min-height: 60px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.install-btn {
		border-radius: 4px !important;
		color: #40a9ff;
		background-color: #fff;
		border-color: #40a9ff;
		padding: 0 50px 0;
		font-weight: 600;
		font-size: 12px;
		margin-top: 20px;
	}

	.enabled-tab-containt {
		display: inline-block;
		margin-left: 20px;
		width: 80%;
	}
	.enabled-tab-heading {
		font-size: 20px;
		letter-spacing: 0;
		color: #2e394c;
		font-weight: 600;
		opacity: 1;
		margin: 14px 0 0 0;
	}

	.enabled-tab-sub-text {
		font-size: 16px;
		letter-spacing: 0;
		color: #2e394c;
		opacity: 1;
		padding-right: 10px;
	}
	.configure-button {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.configuration-sub-text {
		text-align: left;
		font-style: italic;
		letter-spacing: 0;
		color: #798aa0;
		opacity: 1;
	}
	.enabled-integrations-icon {
		width: 80px !important;
		height: 80px !important;
		margin-top: -30px;
	}
	.integrations-install-btn {
		border-radius: 40px !important;
		color: #40a9ff;
		background-color: #fff;
		border-color: #40a9ff;
		padding: 0 30px 0;
		font-weight: 600;
		font-size: 12px;
		margin-right: -30px;
		float: right;
		width: 134px;
	}
	.integrations-disable-btn {
		background: transparent linear-gradient(180deg, #f5566c 0%, #f12836 100%) 0% 0% no-repeat padding-box;
		box-shadow: 0px 2px 5px #0000001a;
		border: 1px solid #e53c42;
		color: #fff;
		border-radius: 4px !important;
		padding: 0 30px 0;
		font-weight: 600;
		font-size: 12px;
		margin-right: -30px;
		float: right;
		width: 134px;
	}
	.table-box-container {
		margin-bottom: 50px;
	}
	.worning-integrations-icon {
		height: 30px;
		width: 30px;
	}
	.collapse-form-padding {
		padding: 20px;
	}
	.app-id {
		color: #bcc9d9;
		font-weight: 600;
		font-family: monospace;
		display: inline-block;
		float: left;
	}
	.txt-app-id {
		color: #4b5564;
		margin-left: 4px;
		display: inline-block;
		float: left;
	}
	.created-date {
		color: #4b5564;
		margin-left: 20px;
		float: left;
		width: 75%;
	}
	.conn-created-date {
		color: #4b5564;
		left: 20px;
		width: 100%;
		position: relative;
	}
	.clock-icon {
		-webkit-mask-image: url("../../../image/clock-icon.svg");
		-webkit-mask-repeat: no-repeat;
		position: relative;
		height: 15px;
		width: 15px;
		background-color: #bcc9d9;
		-webkit-mask-size: 15px;
	}
	.user-icon-image {
		-webkit-mask-image: url("../../../image/user-avatar.png");
		-webkit-mask-repeat: no-repeat;
		position: relative;
		height: 30px;
		width: 30px;
		background-color: #bcc9d9;
		-webkit-mask-size: 30px;
	}
	.clock-icon.edit {
		float: left;
		top: 3px;
		margin-right: 5px;
	}
	.timelapse-icon {
		-webkit-mask-image: url("../../../image/timelapse-icon.svg");
		-webkit-mask-repeat: no-repeat;
		position: relative;
		height: 15px;
		width: 15px;
		background-color: #bcc9d9;
		-webkit-mask-size: 15px;
		float: left;
		top: 3px;
		margin-right: 5px;
	}

	.app-image-caitainer {
		display: inline-block;
		width: 84px;
		height: 84px;
		background-color: #5fb0e5;
		position: relative;
		float: left;
		.clock-icon-edit {
			background-color: white;
			height: 32px;
			width: 32px;
			-webkit-mask-size: 32px;
			top: 26px;
			left: 26px;
		}
	}
	.app-image-shadow {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.25) 100%);
		display: inline-block;
		position: absolute;
		height: 40px;
		width: 83px;
		left: 0;
		top: 43px;
	}
	.edit-icon {
		-webkit-mask-image: url("../../../image/edit-icon.svg");
		-webkit-mask-repeat: no-repeat;
		height: 15px;
		width: 15px;
		background-color: #ffffff;
		-webkit-mask-size: 15px;
		position: absolute;
		right: 5px;
		bottom: 5px;
		top: unset;
	}
	.app-icon {
		height: 82px;
		width: 82px;
		top: 1px;
		left: 1px;
		position: relative;
	}
	.app-image-caitainer .img {
		box-shadow: inset 10px -10px 25px rgba(0, 0, 0, 0.6);
	}
	.upload-btn-wrapper {
		position: absolute;
		overflow: hidden;
		display: inline-block;
	}

	.upload-btn-wrapper input[type="file"] {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}

	.copy-icon {
		-webkit-mask-image: url("../../../image/copy-icon.svg");
		-webkit-mask-repeat: no-repeat;
		position: relative;
		height: 15px;
		width: 15px;
		background-color: #bcc9d9;
		-webkit-mask-size: 15px;
		cursor: pointer;
		z-index: 1;
	}

	.copy-icon.edit {
		float: right;
		position: absolute;
		right: 15px;
		bottom: 29px;
	}

	.copy-icon.edit.overide {
		bottom: 44px;
	}

	.tab-section .customer-info {
		padding: 15px 120px 30px;
	}
	.endpoint-enabled-integrations-icon {
		width: 80px !important;
		height: 80px !important;
		vertical-align: top;
		margin-top: 20px;
		margin-left: 12px;
		display: inline-block;
		border: 1px solid #66bdff;
		border-radius: 50%;
		text-align: center;
	}
	.integaration-logo-txt {
		top: 25px;
		position: relative;
		font-size: 20px;
		font-weight: 600;
		color: #2e394c;
	}
	.txt-add-application {
		color: #8191a5;
		font-family: nunito-semiBold;
		margin-bottom: 20px;
	}

	label {
		line-height: 2;
		color: #151515;
		font-family: nunito-semiBold;
	}

	.tab-section .customer-info .MuiInputBase-input.Mui-disabled {
		color: #7889a0;
	}

	.feild-desc {
		color: #2e394c;
		font: Italic 14px/0px Nunito;
		margin-bottom: 30px;
		font-family: nunito-italic;
		font-size: 13px;
	}

	.consent-desc {
		margin-top: 13px;
	}
	.feild-desc-text {
		line-height: 14px;
	}

	.app-logo.feild-desc {
		font: Italic 14px/16px Nunito;
		font-family: nunito-italic;
		font-size: 13px;
	}

	.tab-section .customer-info .form-textfield {
		margin-bottom: 30px;
	}
	.time-zone-input {
		margin-bottom: 30px;
	}

	.time-zone-input .ant-select{
		width: 100%;
	}
	.time-zone-input .ant-select-selector{
		padding: 5px  0;
    	height: 40px;
    	border: 1px solid #dfdfe0;
    	box-shadow: unset;
	}

	.tab-section .customer-info .form-textfield.margin-bt {
		margin-bottom: 15px;
	}

	.tab-section .customer-info .form-textfield.desc-margin {
		margin-bottom: 15px;
	}

	.tab-section .customer-info .form-textfield.desc-logo-margin {
		margin-bottom: 7px;
	}

	.form-textfield.readonly-text {
		background-color: #f8f9fa;
		font-family: nunito-semiBold;

		.MuiOutlinedInput-input {
			color: #7889a0;
		}

		.MuiOutlinedInput-notchedOutline,
		.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: #f8f9fa;
		}
	}

	.MuiOutlinedInput-multiline {
		padding: unset;
	}

	.switch-box.ant-switch {
		border-radius: 100px !important;
		background-color: #f8f9fa;
		border: 1px solid #ebeff5;
		margin-bottom: 2px;
	}

	.ant-switch-checked {
		background-color: #f8f9fa;
	}

	.ant-switch::after {
		background-color: #9cacc1;
	}
	.ant-switch.ant-switch-checked::after {
		background-color: #70d978;
	}

	.txt-consent {
		color: #2e394c;
		margin-left: 5px;
	}

	.btn-consent,
	.advance-setting {
		margin-top: 2px;
		float: right;
		color: #2695ed;
		font-weight: 600;
		font-size: 12px;
		cursor: pointer;
	}

	.advance-setting {
		float: unset;
		margin-top: 10px;
		margin-bottom: 15px;
		display: inline-block;
	}

	.advance-box {
		border: 1px solid #acd6f6;
		background-color: #f2f6fa;
		padding: 15px 25px 5px;

		.title {
			color: #656667;
			font-size: 12px;
			font-weight: 600;
			margin-bottom: 5px;
		}

		.MuiOutlinedInput-notchedOutline,
		.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
		.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
			border-color: #f2f6fa;
			border-width: 0;
			border-style: none;
		}

		.MuiOutlinedInput-input {
			background-color: white;
			width: 65%;
			padding: 9px 10px;
			border: 1px solid #e9ebee;
		}
	}

	.tab-section .customer-info .btn-save {
		float: left;
		margin-top: 35px;
		width: 80px;
		height: 35px;
	}

	.txt-auth {
		font-size: 14px;
		font-family: nunito-bold;
		color: #151515;
	}

	.auth-line {
		background-color: #f2f4f7;
		height: 2px;
		width: 79%;
		position: relative;
		bottom: 14px;
		left: 147px;
	}
	.auth-line-multiline {
		background-color: #f2f4f7;
		height: 2px;
		position: relative;
		bottom: -16px;
	}

	.auth-line.punch-line {
		left: 195px;
		width: 72%;
	}

	.auth-line.review-line {
		left: 200px;
		width: 72%;
	}

	.mg-bt {
		margin-bottom: 30px;
	}

	.connection-container {
		padding: 30px;
	}

	.connection-table-box {
		.ant-table-thead > tr > th {
			width: 40%;
		}
	}

	.integrations-container-width {
		width: 80%;
		.ant-tabs-nav {
			width: 100%;
		}
		.ant-tabs-tab {
			width: 50%;
			text-align: center;
		}

		.customer-info {
			padding: 15px 0px 30px;
		}
		.integrations-green-circle {
			height: 15px;
			width: 15px;
			background-color: #14cc4d;
			border-radius: 50%;
			float: right;
			position: relative;
			top: -20px;
			left: 23px;
		}
		.install-btn.instaled {
			color: rgba(0, 0, 0, 0.25);
			background-color: #f5f5f5;
			border-color: #d9d9d9;
			text-shadow: none;
			box-shadow: none;
		}
	}

	.sec-field {
		.MuiInputBase-input.MuiOutlinedInput-input {
			padding-right: 30px;
		}
	}
}

.delete-account.consent-form .delete-btn.ok-btn {
	background-image: linear-gradient(#2799f2, #0c77e3);
	border: 1px solid #2799f2;
}

.rdw-editor-wrapper {
	border: 1px solid #f1f1f1;

	.rdw-editor-toolbar {
		border: unset;
		border-bottom: 1px solid #f1f1f1;
		background-color: #ebeef2;
	}

	.rdw-editor-main {
		padding: 0 15px;
		max-height: 250px;
	}

	.rdw-history-wrapper,
	.rdw-inline-wrapper,
	.rdw-text-align-wrapper,
	.rdw-list-wrapper {
		background-color: white;
		padding: 5px 0;
		margin-right: 20px;
	}

	.rdw-block-wrapper {
		margin-right: 20px;
	}

	.rdw-dropdown-optionwrapper {
		max-height: 180px;
	}
}

.ant-modal.delete-account.consent-form {
	width: 1000px !important;
}

.delete-account.consent-form .footer-box .cancle-btn {
	padding: 5px 70px;
	width: unset;
}

.select-input.punch-type {
	margin-bottom: 15px;

	.ant-select-selection--single {
		height: 45px;
		padding: 8px 0px;
	}
}

.rdw-editor-wrapper .rdw-editor-main {
	padding: 15px;

	.public-DraftStyleDefault-block {
		margin: 2px 0;
	}

	.DraftEditor-editorContainer,
	.DraftEditor-root,
	.public-DraftEditor-content {
		max-height: 165px;
	}
}

.txt-learn {
	color: #0186ea;
	font-size: 12px;
	cursor: pointer;
}
.req-box-conta {
	margin-top: 20px;
}

.margin-bt {
	margin-bottom: 15px;
}

.enabled-integration {
	.hr-line {
		border: 1px solid #e6eaee;
		width: calc(100% - 120px);
		display: inline-block;
	}
	.collapse-text {
		width: 100%;
		display: inline-block;
		text-align: right;
		color: #096dd9;
		font-family: "nunito-Bold";
		cursor: pointer;
		margin-top: 45px;
	}
	.margin-bottom-15 {
		margin-bottom: 15px;
	}
	.dayforceRedstoneCred {
		border: 1px solid #e2e3e3;
		padding: 10px;
	}
	.SecritBtnBox {
		display: inline-block;
		width: 100%;
		text-align: right;
	}
	.SaveSecritBtn {
		display: inline-block;
		background-color: #2465bf;
		margin: 5px;
		padding: 6px 14px;
		color: #fff;
		font-size: 14px;
		border-radius: 4px;
		cursor: pointer;
	}
	.inline-btn{
		display: inline-block;
		.clearSecritBtn {
			display: inline-block;
			background-color: #de4646;
			margin: 5px;
			padding: 6px 14px;
			color: #fff;
			font-size: 14px;
			border-radius: 4px;
			cursor: pointer;
		}
		.clearSecritBtn.disabled {
			pointer-events: none;
		}
		.SaveSecritBtn.disabled {
			pointer-events: none;
		}
	}
}
.disabled-wrapper{
	cursor: not-allowed;
}
.edit-json {
	.jsoneditor-mode-code .jsoneditor-outer .jsoneditor-text {
		min-height: 400px;
	}
	.ant-btn-primary {
		margin-top: 10px;
	}
}
.enabled-tab-box {
	display: inline-block;
	width: 100%;
	position: relative;
	box-shadow: 0px 2px 5px #535f6e33;
	border-radius: 6px;
	min-height: 115px;
}
.button-box-container {
	float: right;
}
.tab-heading-container {
	font-size: 14px;
	font-weight: 600;
	padding: 10px;
	border: 1px solid #dbdada;
	margin: 10px;
}
.tab-ans-tab {
	text-align: right;
	float: right;
	font-weight: 100;
}
.advance-text-box {
	width: 90%;
	margin-top: 10px;
}
.advance-text-box-right {
	width: 90%;
	float: right;
	margin-top: 10px;
}
.QR-text-box-right {
	width: 100%;
	// float: right;
	margin-top: 10px;
	border-radius: 4px;
}
.adv-switch-box {
	margin-top: 30px;
}
.advance-tab-heading {
	margin-top: -50px;
	padding-bottom: 7px;
	color: #1890ff;
	font-family: nunito-semiBold;
	border-bottom: 1px solid #dbdbdb;
}

.auth-tab-heading {
	margin-top: 10px;
	padding-bottom: 7px;
	color: #1890ff;
	font-family: nunito-semiBold;
	border-bottom: 1px solid #dbdbdb;
	margin-bottom: 10px;
}

// .MuiOutlinedInput-root:hover{
// 	border-color:#f8f9fa;
// }
.advance-tab-heading-text {
	padding-bottom: 9px;
	color: #1890ff;
	font-family: nunito-semiBold;
	margin-top: 80px;
	border-bottom: 1px solid #dbdbdb;
}
.advcheck-box {
	position: relative;
	top: 35px;
}
.advance-text-box-right label {
	margin-bottom: 1px;
}
.advance-text-box label {
	margin-bottom: 1px;
}
.advance-text-box-right .MuiOutlinedInput-input {
	padding: 10px 12px;
}
.advance-text-box .MuiOutlinedInput-input {
	padding: 10px 12px;
}
.application-detail .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: rgba(0, 0, 0, 0.23);
}

.application-detail .MuiOutlinedInput-notchedOutline {
	border-color: rgba(39, 35, 35, 0.13);
}

.application-detail .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: rgba(0, 0, 0, 0.23);
	border-width: 1px;
}
.adv-save-btn {
	margin-top: 20px;
	float: right;
}
.auth-switch-suvtxt {
	color: #2e394c;
	margin-bottom: 20px;
	font-family: nunito-italic;
	font-size: 13px;
	margin-left: 20px;
}

.disable-txt-header {
	font-size: 25px;
	text-align: center;
	color: #2e394c;
	margin-bottom: 25px;
	font-family: "nunito-semiBold";
	margin-top: 60px;
}
.tharmal-container {
	margin: 20px;
}
.tharmal-table-box {
	margin-top: 40px;
}
.config-container {
	padding: 1px 20px;
	margin-top: -30px;
	// .auth-form-container{

	// }
	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: rgba(0, 0, 0, 0.23);
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: rgba(39, 35, 35, 0.13);
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: rgba(0, 0, 0, 0.23);
		border-width: 1px;
	}

	.auth-txt-consent {
		line-height: 3;
		color: #151515;
		font-family: nunito-semiBold;
		font-size: 14px;
		margin-top: 10px;
	}
	.bWPPed .ant-select-selection {
		padding: 10px 0;
	}
	.ant-select-selection--single {
		position: relative;
		height: 45px;
		cursor: pointer;
	}
	.select-input {
		width: 100%;
		padding: 1px;
	}
	.ant-select-selection--single .ant-select-selection__rendered {
		margin-right: 24px;
		padding: 8px;
	}
	.auth-form-suvtxt {
		color: #2e394c;
		// margin-bottom: 30px;
		font-family: nunito-italic;
		font-size: 13px;
	}
	.auth-form-heading {
		opacity: 1;
		border-bottom: 1px solid #e6eaee;
		font-size: 14px;
		padding-bottom: 20px;
		margin-top: 10px;
		color: #798aa0;
		margin-bottom: 20px;
	}
	.MuiOutlinedInput-input {
		padding: 14px;
	}
	.switch-right-padd {
		margin-left: 20px;
	}
	.buttons-row-icon {
		height: 40px;
		display: inline-block;
	}
	.auth-header {
		display: inline-block;
		width: 100%;
		position: relative;
		border-bottom: 1px solid #e6eaee;
		margin-bottom: 20px;
		padding: 10px.0;
	}
	.auth-txt-box {
		display: inline-block;
		margin-left: 30px;
		position: relative;
		top: 10px;
	}
	.auth-boxheading-txt {
		color: #2e394c;
		font-weight: 600;
		line-height: 20px;
	}
	.auth-boxsub-txt {
		color: #2e394c;
	}
}

// .auth-switch-box-pan{
// 	padding: 10px 0;
// }

.btn-delete {
	background: transparent linear-gradient(180deg, #f5566c 0%, #f12836 100%) 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #0000001a;
	border: 1px solid #e53c42;
	color: #fff;
	border-radius: 4px !important;
	font-weight: 600;
	font-size: 12px;
}

.btn-add-secret {
	background-image: linear-gradient(#2799f2, #0c77e3);
	padding: 0px 25px;
	border-radius: 2px;
	color: white;
	line-height: 34px;
	position: relative;
	top: -18px;
	cursor: pointer;
	float: right;
	height: 34px;
	font-size: 11px;
	font-family: nunito-black;
	text-transform: uppercase;
}
.modal-secret-add-btn {
	background-image: linear-gradient(#2a94e4e3, #0675c7);
	border: 0px!important;
    font-family: nunito-black;
	padding: 0px 25px;
	border-radius: 2px;
	color: white;
	line-height: 34px;
	margin: 18px 0;
	cursor: pointer;
	float: right;
	height: 34px;
	font-size: 11px;
	font-family: nunito-black;
	text-transform: uppercase;

}
.modal-secret-outputbox {
	border-top: 1px solid #dbdada;
	padding: 10px 0;
	margin-top: 20px;
}
.date-form-textfield {
	width: 100%;
	height: 44px;
}
.date-form-textfield .ant-input {
	height: 44px;
}
.advance-text-box,
.advance-text-box-right {
	.react-tagsinput-input {
		width: 200px;
	}
	.react-tagsinput-tag {
		background-color: #40a9ff;
		border-radius: 2px;
		border: 1px solid #40a9ff;
		color: #fff;
	}
	.feild-hint {
		line-height: 25px;
		color: #2e394c;
		font-family: nunito-italic;
		font-size: 13px;
	}
}
.advance-text-box,
.thermal-text-box-right {
	margin-top: 10px;
	.react-tagsinput-input {
		width: 200px;
	}
	.react-tagsinput-tag {
		background-color: #40a9ff;
		border-radius: 2px;
		border: 1px solid #40a9ff;
		color: #fff;
	}
	.feild-hint {
		line-height: 25px;
		color: #2e394c;
		font-family: nunito-italic;
		font-size: 13px;
	}
}
.add-btn-margin-rig {
	margin-right: 15px;
}
.remove-btn {
	margin-top: 15px;
}
.remove-btn:hover {
	color: #f23444;
	background-color: #fff;
	border-color: #f23444;
}
.remove-btn:focus {
	background: transparent linear-gradient(180deg, #f5566c 0%, #f12836 100%) 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 5px #0000001a;
	border: 1px solid #e53c42;
	color: #fff;
}
.config-right-padding {
	padding-right: 10px;
	margin-top: 20px;
}
.config-left-padding {
	padding-left: 10px;
	margin-top: 20px;
}
.select-input.ant-select > div {
	padding: 5px;
	min-height: 40px;
	border: 1px solid #dfdfe0;
	box-shadow: unset;
}
.select-input.ant-select.error > div {
	border-color: red;
}
.Certify-container {
	margin-bottom: 30px;
}
