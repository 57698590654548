html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.ant-layout-content{
  padding: 60px 0px 0px !important;
}

// .modal_header{
//   position: relative;
//   font-size: 20px;
//   padding: 8px 0px;
// }
// .modal_header p span:first-child{
//   margin-right: 20px
// }
// .modal_header p span i{
//   color: #798aa0
// }
// .modal_right_icon{
//   position: absolute;
//   right: 0px;
//   top:8px;
// }
// .modal_right_icon i{
//   color: #2197f1;
//   font-size: 25px;
// }

// .modal_right_icon_help{
//   position: absolute;
//   right: 0px;
//   top:8px;
// }
// .modal_right_icon_help i{
//   color: #2197f1;
//   font-size: 25px;
// }

// .ant-modal-body{
//   background: #f8f9fb;
// }

// .ant-modal-close{
//   right: -15px !important;
//   top: -15px !important;
//   background: transparent !important
// }

// .ant-modal-close-x{
//   border-radius: 50%;
//   border: 2px solid #333;
//   background: #fff;    
//   color: #333;    
//   width: 35px !important;
//   height: 35px !important;
//   line-height: 35px !important;
// }
.menu-devider-line{
  height: 1px;
  background-color: #BCC9D9;
  margin: 5px 0;
}
.menu-icon-padding{
  margin-right: 10px;
  color:#BCC9D9;
  object-fit: contain;
  height: 14px;
}
.menu-icon-padding1{
  margin-right: 25px;
}
.menu-tab-heading{
  font-weight: 600;
}
.not-allowed{
  cursor: unset;
  background-color: unset !important;
}
.side-menu-email-text{
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  position: relative;
  top: 4px;
}

.user-popover{
  // left: 30px !important;
  // top: 60px !important;
  .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
}
.margin-left-small{
  margin-left: 10px;
}
.margin-bottom-small{
  margin-bottom: 10px;
}
.font-bold{
  font-weight: bold;
}
.font-thin{
  font-weight: 500;
}
.font-auto-unlock{
  font-size: 16px !important;
}
