.time-interpretation-container{
    .top-container{
        padding: 20px 30px;
        .ant-select { 	
            width: 80%; 
        }
        .ant-select > div { 	
            padding: 5px 0; 	
            height: 45px; 	
            border: 1px solid #dfdfe0; 	
            box-shadow: unset; 
        }
        .clickable-text:hover{
            color:#40A9FF;
            text-decoration: underline;
            cursor: pointer;
        } 
    }
    .table-container{
        padding: 20px 30px;
    }
}
.timeInterpretation-customer-header{
    padding: 10px 20px 20px 30px !important;
    background-color: #ffffff;
}
.timeInterpretation-top-container{
    
    .ant-select { 	
        width: 50%;
        margin-bottom: 10px; 
        height: 45px;
    }
    .MuiOutlinedInput-input {
        padding: 14px;
        font-size: 14px;
    }
    // .MuiOutlinedInput-notchedOutline{
    //     border-color: #dfdfe0;
    // }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
      }
      
      .MuiOutlinedInput-notchedOutline {
          border-color: rgba(39, 35, 35, 0.13);
      }
      
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline  {
          border-color: rgba(0, 0, 0, 0.23);
          border-width: 1px;
      }
    .MuiFormControl-marginNormal{
        width: 50%;
        margin-bottom: 10px; 
        margin-top: 0px !important;
        
    }
    .MuiInputBase-input:focus {
        outline: 0;
    }
    .MuiFormControl-marginNormal > .MuiInputBase-formControl{
        height: 45px;
    }
    .ant-select > div { 	
        padding: 5px 0; 	
        height: 45px; 	
        border: 1px solid #dfdfe0; 	
        box-shadow: unset; 
    } 
}
.timeInterpretation-table{
    padding: 20px 0px;
}
.group-detail{
    padding: 20px 0px;
    background-color: #FFFFFF;
    height: 100%;
}
.spoof-detail .back-btn .anticon {
    vertical-align: 1px;
}
.back-btn {
    font-family: "nunito-regular";
    padding: 0 30px;
    color: #798AA0;
    cursor: pointer;
    font-size: 11px;
    display: inline-block;
}
.customer-details{
    display: inline-block;
    padding: 20px 0px;
}
    .customer-name{
      text-align: left;
      letter-spacing: 0;
      color: #363636;
    //   font-family: $font-family-nunito-regular;
      font-size: 20px;
    }
    .customer-id{
      color: #798AA0;
      font-size: 12px;
    //   font-family: $font-family-nunito-italic
    }
    .app-id {
        color: #bcc9d9;
        font-weight: 600;
        font-family: monospace;
        float: left;
        display: inline-block;
    }
    .app-id-txt{
        color: #2E394C;
        margin-left: 8px;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #1890ff;
        font-weight: 500;
    }
    .tab-section .ant-tabs-nav .ant-tabs-tab {
        font-size: 13px;
        box-sizing: border-box;
        height: 100%;
        margin: 0 32px 0 0;
        padding: 12px  16px;
        text-decoration: none;
        cursor: pointer;
    }
    .date-row{
        margin-top: 20px;
    }
    .timeInterpretation-customer-header label{
        margin-bottom: .1rem;
    }
    .buttons-time-slot-btn{
		margin-top: -12px;
        background-image: linear-gradient(#2799f2, #0C77E3);
        padding: 0px 10px;
        border-radius: 2px;
        color: white;
        line-height: 34px;
        position: relative;
        top: 5px;
        cursor: pointer;
        display: inline-block;
        float: right;
        height: 34px;
        font-size: 11px;
        font-family: nunito-black;
        text-transform: uppercase;
    }
    .cancle-btn {
        border: 1px solid #0B87E4;
        border-radius: 3px !important;
        opacity: 1;
        font-family: "nunito-black";
        width: unset;
        font-size: 11px;
        color: #0B87E4;
        height: 32px;
        cursor: pointer;
        padding: 7px 20px;
        margin-right: 10px;
    }
    .cancle-btn:focus{
        border: 1px solid #0B87E4;
        outline: none;
    }
    .timeInterpretation-footer-btns{
        float:right;
        margin-top: 20px;
    }
    .timeInterpretation-delete{
        padding: 20px 20px 20px 30px !important;
        border-top: 1px solid #E6EAEE;
    }
  
  .spoof-detail .spoof-detail-box{
    padding: 0px 30px;
}
//...........table view..........
.table-header-row{
    background-color: #E7E8E8;
    padding: 10px 15px;
    border-radius: 6px 6px 0 0;
    margin-top: 10px;
}
.table-header-heading{
    font-size: 10.5px;
    font-family: nunito-bold;
    line-height: 20px;
    color: #000000;
    position: relative;
    top: 2px;

}
.ant-time-picker-input:hover{
    border-color: #d8d7d7;
}
.table-input .ant-time-picker{
    min-width:unset !important;
    width: 90%;
}
.table-input .ant-time-picker-input{
    height: 40px !important;
}
.table-dropdown .ant-select { 	
    width: 90%; 
}
.table-input{
    padding: 10px 15px;
}
.cross-icon{
    background-image: url('../../../image/cross-icon.png');
    height: 16px;
    width: 16px;
    cursor: pointer;
    background-size: 16px;
    position: relative;
    // top: 11px;
    float: right;
    right: 20px;
}

.no-data-icon-box{
    padding: 40px;
}
.no-data-icon{
    background-image: url('../../../image/nodata.png');
    height: 70px;
    width: 70px;
    background-size: 70px;
    margin: auto;
}
.select-input.ant-select.error > div {
	border: 1px solid red

}
.select-input.error > input {
	border: 1px solid red

}
.helper-text{
    color: #f44336;
    font-size: 0.80rem;
    margin-left: 14px;
    margin-right: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
}
.helper-text1{
    color: #f44336;
    font-size: 1rem;
    margin-left: 14px;
    margin-right: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
}