@font-face {
  font-family: nunito-black;
  src: url('./font/Nunito/Nunito-Black.ttf');
}

@font-face {
  font-family: nunito-black-italic;
  src: url('./font/Nunito/Nunito-BlackItalic.ttf');
}

@font-face {
  font-family: nunito-bold;
  src: url('./font/Nunito/Nunito-Bold.ttf');
}

@font-face {
  font-family: nunito-bold-italic;
  src: url('./font/Nunito/Nunito-BoldItalic.ttf');
}

@font-face {
  font-family: nunito-extra-bold;
  src: url('./font/Nunito/Nunito-ExtraBold.ttf');
}

@font-face {
  font-family: nunito-extra-bold-italic;
  src: url('./font/Nunito/Nunito-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: nunito-extra-light;
  src: url('./font/Nunito/Nunito-ExtraLight.ttf');
}

@font-face {
  font-family: nunito-extra-light-italic;
  src: url('./font/Nunito/Nunito-ExtraLightItalic.ttf');
}

@font-face {
  font-family: nunito-italic;
  src: url('./font/Nunito/Nunito-Italic.ttf');
}

@font-face {
  font-family: nunito-light;
  src: url('./font/Nunito/Nunito-Light.ttf');
}

@font-face {
  font-family: nunito-light-italic;
  src: url('./font/Nunito/Nunito-LightItalic.ttf');
}

@font-face {
  font-family: nunito-regular;
  src: url('./font/Nunito/Nunito-Regular.ttf');
}

@font-face {
  font-family: nunito-semiBold;
  src: url('./font/Nunito/Nunito-SemiBold.ttf');
}

@font-face {
  font-family: nunito-semi-bold-italic;
  src: url('./font/Nunito/Nunito-SemiBoldItalic.ttf');
}

body {
  margin: 0;
  font-family: "nunito-regular", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiFormHelperText-root {
  font-family: "nunito-regular", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

iframe:not(.googleLogin) {
  pointer-events: none;
  width: 0px;
  height: 0px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  display: none;
}

.import-upload-file-link,
.import-upload-file-link a {
  text-decoration: underline;
  color: #0186EA;
}

.relative {
  position: relative;
}