@import '../../../variables.scss';
.user-detail-box {
    background-color: white;
    padding: 20px 30px;
    .ant-select{
        width: 100%;
    }
    .redio-box{
        margin: 20px 0;
    }
    .select-input.ant-select > div {
        padding: 5px 0;
        height: 100%;
        border: 1px solid #dfdfe0;
        box-shadow: unset;
    }
    .select-input.error.ant-select>div {
        border: 1px solid red;
    }
    .user-download-button {
        border-radius: 4px !important;
        color: #40a9ff;
        background-color: #fff;
        border-color: #40a9ff;
        margin-left: 20px;
        font-weight: 600;
        font-size: 12px;
        margin-top: 34px;
    }
    .report-label-input-btn{
        left: 10px;
		position: relative;
        top: -1px;
        font-family: 'nunito-semiBold';
    }
    .group-redio-input-btn{
        height: 16px;
        width: 16px;
    }
    .input-sub-txt{
        color: #2E394C;
        font: Italic 14px/0px Nunito;
        margin-bottom: 30px;
        font-family: nunito-italic;
        font-size: 13px;
        margin-top: 15px;
    }
}
.user-report-footer{
    width: 100%;
    margin-top: 30px;
}
.report-create-btn {
    background-image: linear-gradient(rgba(42, 148, 228, 0.8901960784), #0675c7);
    font-size: 11px;
    font-weight: 600;
    float: left;
    width: 35%;
    margin-left: 59px;
    height: 34px;
    margin-right: 72px;
}
.report-cancle-btn {
    border: 1px solid #0B87E4;
    border-radius: 3px !important;
    opacity: 1;
    font-family: "nunito-black";
    width: unset;
    font-size: 11px;
    color: #0B87E4;
    height: 32px;
    cursor: pointer;
    padding: 7px 20px;
    margin-right: 10px;
}
.report-detail-box{
    background-color: white;
    padding: 10px 10px 30px 10px;
    .ant-select {
        width: 100%;
        margin-top: 0px;
    }
    .ant-row{
        margin-bottom: 10px;
    }
}
