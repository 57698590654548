@import "./variables.scss";

body {
  overflow-y: clip;
}

.App-header {
  background-color: $primary-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.oloid-box {
  padding: 25px;

  img {
    height: 70px;
    float: left;
  }

  .text-oloid {
    font-family: nunito-bold;
    font-size: 25px;
    position: relative;
    top: 10px;
    color: rgb(46, 57, 75);
  }
}

.btn-save {
  // background: transparent linear-gradient(180deg, #249af3 0%, #007ddc 100%) 0%
  //   0% no-repeat padding-box;
  border: 1px solid #0b87e4;
  border-radius: 3px !important;
  opacity: 1;
  font-family: "nunito-black";
  width: 90px;
  font-size: 11px;
  color: #fff;
  height: 32px;
  cursor: pointer;
}

.del-box {
  border: 1px solid #ff9900;
  padding: 20px;
  display: flex;
  margin-right: 15px;

  .icon-container {
    .warnnig-icon {
      background-image: url("./image/warning-24px.svg");
      width: 50px;
      height: 50px;
      background-size: 50px;
    }
  }

  .warrning-msg {
    width: 75%;
    padding-left: 20px;
    margin: auto;

    .warnnig-text {
      color: #363636;
      font-size: 20px;
      font-family: $font-family-nunito-semiBold;
    }

    .warnnig-sub-text {
      color: #798aa0;
      font-size: 11px;
      font-family: $font-family-nunito-italic;
      line-height: 14px;
    }
  }

  .btn-cantainer {
    .button-box-container {
      margin-top: 6px;
    }

    .btn-delete {
      padding: 5px 35px;
      height: 34px;
      color: #fff;
      letter-spacing: 1px;
      background: transparent linear-gradient(180deg, #f5566c 0%, #f12836 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 5px #0000001a;
      border: 1px solid #e53c42;
      border-radius: 3px;
      font-family: "nunito-black";
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.del-app-container {
  padding: 15px 120px 20px;
}

.ui_block.ui-block-loader {
  display: none;
}

.ui-block-loader {
  display: flex;
  align-items: center;
  overflow: auto;
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff87;
}

.raw-json {
  padding: 20px 30px;

  .string-json {
    background-color: #fafbfc;
    padding: 20px;
  }
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.file-drag {
  border-color: #5fb0e5;
}

.ant-table .ant-table-column-title {
  font-size: 10.5px;
  font-family: nunito-bold;
}

.ant-table .ant-table-row {
  font-size: 13px;
}

.ant-table-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}

.ant-table .ant-table-thead > tr > th {
  overflow-wrap: initial;
  word-break: initial;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  padding-left: 12px;
  font-size: 14px !important;
  // color: #000 !important;
  font-weight: 400 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.ant-select-selection-placeholder {
  color: #a6a6a6 !important;
}

.ant-select {
  min-width: 60%;
}

form .ant-select {
  width: 100%;
}

.ant-select.select-input.ant-select-single.ant-select-show-arrow
  .ant-select-selector {
  padding: 5px 0;
  height: 40px;
  border: 1px solid #dfdfe0;
  box-shadow: unset;
}

.ant-switch-handle {
  top: 1px;
}

th.ant-table-cell {
  font-size: 10.5px;
  font-family: nunito-bold;
}

.ant-switch .ant-switch-handle::before {
  background-color: #9cacc1;
}

.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #70d978;
}

.ant-pagination-item-active {
  font-weight: bold;
}

.ant-pagination-item a {
  padding: 0 12px;
}

.paging {
  text-align: right;

  .page-item {
    margin: 10px;
    font-weight: bold;
    color: black;
    display: inline-flex;
    font-family: "nunito-semiBold";
  }

  .disabled {
    pointer-events: none;
    color: #b3b1b1;
  }
}

.opblock-options {
  display: none;
}

.required-asterisk {
  color: red;
}
.application-detail .switch-box.ant-switch,
.ant-switch.switch-box,
.ant-switch {
  background-image: none !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #7b7b7b;
}
.ant-select {
  &.error {
    .ant-select-selection-placeholder {
      color: #f44336;
    }
  }
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  top: 50%;
  transform: translateY(-50%);
  bottom: unset;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
  .ant-collapse-expand-icon {
    display: inline-flex;
  }
}
.ant-checkbox-wrapper span:not(.ant-checkbox) {
  user-select: none;
}
.ant-switch-handle {
  pointer-events: none;
}
