.create-endpoint{
    width: 550px !important;
    .ant-modal-body{
        padding: 35px 40px 15px;
    }

    .ant-modal-footer{
        padding: 20px 16px 30px;
    }

    .application-type{
        margin-top: 15px;
    }

    .types{
        padding: 0 40px;
    }

    .txt-type{
        font-family: nunito-bold;
        font-size: 14px;
        color: rgb(21, 21, 21);
        margin-top: 10px;
    }
    .create-app-type-name{
        position: relative;
        top: 15px;
        color: #ffffff;
        font-weight: 600;
    }
    .box-col-padding{
        padding: 8px;
    }

    .type-border{
        display: inline-block;
        border: 1px solid rgb(228, 233, 240);
        padding: 15px 2px;
        cursor: pointer;
        border-radius: 4px;
        width: 100%;
        text-align: center;
    }

    .type-border.selected{
        border: 2px solid #0f86ea;
        position: relative;
        margin-top: -1px;
        // left: -3px;
        // margin-top: 26px;
    }

    .type-border.notSelected{
        border: 1px solid #f44336;
    }

    .error-required{
        color: #f44336;
        margin-left: 40px;
        margin-top: 5px;
    }

    .type-icon{
        background-color: #bcc9d9;
        height: 50px;
        width: 50px;
        margin: auto;
    }

    .txt-heading{
        color: rgb(97, 106, 120);
        margin-top: 20px;
    }

    .mr-type{
        margin-right: 32px;
    }

    .create-btn{
        background-image: linear-gradient(#2a94e4e3, #0675c7);
        font-family: nunito-black;
    }
    .cancle-btn{
        font-family: nunito-bold;
    }
    .feild-desc {
        color: #2E394C;
        font: Italic 14px/0px Nunito;
        margin-top: 20px;
        font-family: nunito-italic;
        font-size: 13px;
    }
    
    .ant-select.select-input.ant-select-single.ant-select-show-arrow.error {
        .ant-select-selector{
            border: 1px solid red;
        }
    }
}