@import '../../../variables.scss';

.ant-modal.delete-account{
  width: 700px !important;
}

.delete-account{
  .heading-text{
    font-size: 24px;
    text-align: center;
    margin: 10px 0;
    margin-bottom: 20px;
    color: #282828;
    font-family: $font-family-nunito-semiBold;
  }
  .sub-heading-text{
    font-size: 14px;
    line-height: 20px;
    color: #282828;
  }
 
  .marign-bottom-20{
    margin-bottom: 30px;
  }

  .MuiOutlinedInput-input{
    padding: 13px 14px;
    font-size: 14px;
  }

  .MuiInputLabel-formControl{
    top: -7px;
  }

  .MuiFormLabel-root{
    font-size: 14px;
  }

  .MuiFormControl-marginNormal{
    margin-bottom: 0;
    margin-top: 0px;
  }

  .MuiFormHelperText-contained{
    margin: 3px 14px 0;
  }

  .MuiFormHelperText-root{
    font-size: 11px;
  }

  .MuiInputLabel-formControl.MuiInputLabel-shrink{
    top: 0px !important;
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
  
  .MuiOutlinedInput-notchedOutline {
      border-color: rgba(39, 35, 35, 0.13);
  }
  
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline  {
      border-color: rgba(0, 0, 0, 0.23);
      border-width: 1px;
  }
  
  .education-form-textfield{
    width:100%;
    font-size: 10px;
    margin-bottom: 25px;
  }


  .footer-box{
    text-align: center;
    margin: 10px;
    .delete-btn{
      padding: 5px 85px;
      height: 35px;
      color: $white-color;
      letter-spacing: 1px;
      margin: 10px;
      background: transparent linear-gradient(180deg, #F5566C 0%, #F12836 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 5px #0000001A;
      border: 1px solid #E53C42;
      border-radius: 3px;
      opacity: 1;
      font-family: $font-family-nunito-black;
      cursor: pointer;
    }

    .cancle-btn{
      border: 1px solid #178FEA;
      padding: 5px 47px;
      height: 35px;
      color: #178FEA;
      letter-spacing: 1px;
      margin: 10px;
      font-family: $font-family-nunito-bold;
    }
  
    .ant-btn-primary{
      background-color: unset;
      border: 1px solid #178FEA;
    }
  }  
  
}

.delete-account.User .ant-modal-footer{
  padding-top: 0;
}


