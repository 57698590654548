.login-container {
	background-color: #f2f6fa;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: auto;
	.login-box {
		width: 43%;
		margin: auto;
		margin-top: 75px;
		box-shadow: 0 10px 20px -2px rgba(0, 0, 0, 0.14);
		background-color: #ffffff;
		padding: 45px;
		&.center{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin-top: 30px;
			max-width: 80%;
			padding: 30px;
		}
	}
	.login-success-box {
		width: 43%;
		margin: auto;
		margin-top: 75px;
		box-shadow: 0 10px 20px -2px rgba(0, 0, 0, 0.14);
		background-color: #ffffff;
		padding: 50px 45px;
	}

	.txt-login {
		color: #2e394b;
		font-size: 25px;
		margin-bottom: 25px;
		font-family: nunito-black;
	}
	.success-txt-login {
		color: #2e394b;
		font-size: 25px;	
		margin-bottom: 35px;
		font-family: nunito-black;
		text-align: center;
		margin-top: 25px;
	}
	.btn-ok {
		margin-top: 50px;
		width: 70px;
	}
	.successfull-icon {
		background-image: url("../../../image/success.svg");
		height: 50px;
		width: 50px;
		background-size: 50px;
		background-repeat: no-repeat;
	}
	.successfull-icon-container {
		align-items: center;
		display: flex;
		flex-direction: column;
	}

	.forgot-password-secction{
		text-align: right;
	}
	.txt-forgot {
		color: #8a99ab;
		font-size: 12px;
		cursor: pointer;
		display: inline-flex;
	}

	.remember-box {
		margin: 20px 0;

		.square-box {
			height: 20px;
			width: 20px;
			border: 1px solid #ebeff6;
			float: left;
			margin-right: 15px;
			padding: 4px;
			cursor: pointer;

			.green-box {
				height: 10px;
				width: 10px;
				background-color: #65c465;
				border-radius: 2px;
			}
		}
	}

	.btn-login {
		background-image: linear-gradient(#2799f2, #0c77e3);
		color: white;
		font-size: 11px;
		font-family: nunito-black;
		padding: 0px 65px;
		cursor: pointer;
		height: 35px;
		width: 100%;
	}
	.a-divider-break {
		text-align: center;
		position: relative;
		top: 2px;
		padding-top: 1px;
		margin: 20px 0;
		line-height: 0;
	}
	.or-txt {
		line-height: 1;
		font-size: 12px;
		color: #767676;
		font-weight: 400;
		z-index: 2;
		position: relative;
		display: inline-block;
		background-color: #fff;
		padding: 0 8px 0 7px;
	}
	.a-divider-break:after,
	.a-divider-break:after {
		content: "";
		width: 100%;
		background-color: transparent;
		display: block;
		height: 1px;
		border-top: 1px solid #e7e7e7;
		position: absolute;
		top: 50%;
		margin-top: -1px;
		z-index: 1;
	}
	.sso-btn {
		color: rgb(0, 0, 0);
		font-size: 11px;
		font-family: nunito-black;
		cursor: pointer;
		height: 35px;
		width: 50%;
		box-shadow: 0 2px 4px 0 #00000024;
	}
	.google-btn {
		color: rgb(0, 0, 0);
		font-size: 11px;
		font-family: nunito-black;
		cursor: pointer;
		height: 35px;
		width: 100%;
		box-shadow: 0 2px 4px 0 #00000024;
	}
	.azure-btn {
		color: rgb(0, 0, 0);
		font-size: 11px;
		font-family: nunito-black;
		cursor: pointer;
		height: 35px;
		width: 100%;
		box-shadow: 0 2px 4px 0 #00000024;
	}
	.azure-btn-box {
		padding-left: 10px;
	}
	.google-btn-box {
		padding-right: 10px;
	}
	.login-icons {
		height: 20px;
		margin-right: 10px;
	}
	.login-btn-conta{
		width: 50%;
		margin: auto;
	}

	.txt-desc {
		color: #798aa0;
		margin-bottom: 10px;
		font-size: 14px;
	}

	.txt-login.txt-change {
		margin-bottom: 30px;
	}

	.mg-top {
		margin-top: 30px;
	}

	.mg-bottom {
		margin-bottom: 30px;
	}
	.mg-bottom-10 {
		margin-bottom: 10px;
	}
	.mg-top-10{
		margin-top: 10px;
	}

	.txt-success-msg {
		color: rgb(53, 63, 81);
		font-size: 14px;
		margin: 30px 0;
	}

	.MuiFormControl-root {
		width: 100%;

		.MuiFormLabel-root.Mui-focused {
			top: 0px;
			color: #1890ff;
		}

		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: rgba(0, 0, 0, 0.87);
			border-width: 1px;
		}
		.MuiInputLabel-formControl {
			top: -6px;
			font-size: 14px;
		}

		.MuiOutlinedInput-input {
			padding: 10px;
		}

		::placeholder {
			font-size: 14px;
		}
	}

	.secure .MuiInputBase-input.MuiOutlinedInput-input {
		text-security: disc;
		-webkit-text-security: disc;
	}


		
	.sso-logins{
		width: 20%;
		margin: auto;

	}
	.sso-logins-header{
		text-align: center;
		font-family: nunito-black;
		font-size: 18px;
		color: #364d58;
		margin: 30px 0;
	}

	.sso-login-box {
		box-shadow: 0 10px 20px -2px rgb(0 0 0 / 10%);
		background-color: #ffffff;
		padding: 15px;
		border-radius: 4px;
		margin-bottom: 15px;
		cursor: pointer;
	}
	.sso-login-box .image-logo-container{
		display: inline-block;
	}
	.sso-login-box .image-logo-container > img{
		width: 24px;
	}

	.sso-login-box .sso-name{
		display: inline-block;
		text-align: center;
		width: 80%;
		font-family: 'nunito-bold';
		font-size: 15px;
		color: #364d58;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
	.login-container {
		.login-box {
			width: 85%;
		}
		.otp-box > input{
			width: 30px !important; 
			height: 40px;
		}

		.sso-logins{
			width: 85%;
		}
	}
	.ant-table {
		width: auto;
		overflow: auto;
	}
  }
@media only screen and (min-width: 320px) and (max-width: 767px){
	.login-container {
		.login-box {
			width: 85%;
		}
		.otp-box > input{
			width: 30px !important; 
			height: 40px;
		}
		.sso-logins{
			width: 85%;
		}
	}
	.ant-table {
		width: auto;
		overflow: auto;
	}
}


/* Reset password */
.seprator-row {
	text-align: center;
	position: relative;

	&::after {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		height: 1px;
		width: 100%;
		background: rgba(0, 0, 0, 0.23);
		content: '';
		z-index: 1;
	}

	span {
		display: inline-block;
		border: 1px rgba(0, 0, 0, 0.23) solid;
		padding: 4px;
		border-radius: 100%;
		width: 30px;
		height: 30px;
		font-size: 12px;
		z-index: 2;
		position: relative;
		background: white;
	}
}
.form-textfield {
	&.no-margin {
		margin-top: 0px;
	}

	&.PhoneInput {
		.PhoneInputInput {
			height: 40px;
			border-color: rgba(0, 0, 0, 0.23);
			border-radius: 4px;
			border-width: 1px;
			padding: 0 8px !important;
			outline: none !important;
			color: rgba(0, 0, 0, 0.87);
			font-weight: 4000;
			font-size: 14px;
			margin-bottom: 0px;

			&:hover {
				border-color: rgba(0, 0, 0, 0.87);
			}
		}
	}
}
.sso-login-container{
	background:url('../../../image/loader.gif') center center no-repeat;
}