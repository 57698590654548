.report-container{
	.reports-menu{
		padding: 40px 0px;
		border-right: 1px solid;
		.report-menu-item{
			font-size: 15px;
			font-weight: 600;
			color: black;
			margin-bottom: 10px;
		}
	}
	
	
    .txt-condition{
			margin: 20px 0 10px;
			font-family: nunito-bold;
		}
		
		.txt-customer{
			font-family: nunito-semiBold;
		}
    
    .select-input.ant-select.ant-select-enabled{
        width: 220px;
    }

    .customize-select{
        .select-input.ant-select.ant-select-enabled{
            margin-top: 20px;
        }
    }
	
    .select-input.ant-select > div{
        height: 43px;
        padding: 6px 0 5px;
		}
		
		.select-input.mode-input>div{
			background-color: #dae0e6;
		}

    .MuiFormControl-marginNormal {
        width: 220px;
        margin-bottom: 0px;

        .MuiOutlinedInput-root{
            height: 43px;
        }

        .MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
            border-color: rgb(223, 223, 224);
            border-width: 1px;
        }
		}

		.mode-bullet{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			display: inline-block;
    	margin-right: 25px;
		}
		
		.Online-bullet{
			background-color: #65c465;
		}

		.Offline-bullet{
			background-color: #f39724;
		}

		.btn-update{
			border-radius: 4px !important;
			margin-right: 15px;
			font-weight: 600;
			font-size: 12px;
			background-image: linear-gradient(#2799f2, #0C77E3);
		}

		.button-box{
			margin-top: 60px;
		}

		.clear-btn{
			border-radius: 4px !important;
			color: #40a9ff;
			background-color: #fff;
			border-color: #40a9ff;
			margin-right: 60px;
			font-weight: 600;
			font-size: 12px;
		}

		.ant-table-column-has-actions > div{
			border-bottom: unset;
			font-weight: 500;
		}

		.ant-table-thead > tr > th:nth-child(2), .ant-table-tbody > tr > td:nth-child(2){
			padding: 10px 16px;
		}

		.dropdown{
			display: inline-block;
			margin-top: 20px;

			.mode-button{
				height: 43px;
				width: 220px;
				background-color: #dae0e6;
				border: 1px solid #dfdfe0;
				color: #504d78;
				font-size: 15px;
				outline: none;
				padding: 0 10px;
				border-radius: 4px;

				span{
					float: left;
				}
			}

			.down-dropdown-icon{
				-webkit-mask-image: url('../../../image/down-icon.svg');
				 object-fit: contain;
				 -webkit-mask-size: 10px;
				 -webkit-mask-repeat: no-repeat;
				 background-color: #363636;
				 width: 10px;
				 height: 10px;
				 position: absolute;
				 right: 15px;
				 top: 16px;
				 transition-duration: 0.3s;
			}

			.dropdown-menu.show{
				width: 220px;
				padding: 0;
			}

			.dropdown-item{
				padding: 10px;
			}

			.dropdown-item.active, .dropdown-item:active{
				background-color: #f8f9fa;
			}
		}
		.dropdown.show .down-dropdown-icon.up-dropdown-icon {
			transform: rotate(180deg);
			transition-duration: 0.3s;
		}

		.MuiInput-underline:before,
		.MuiInput-underline:hover:not(.Mui-disabled):before{
			border-bottom:unset;
		}

		.datetime .MuiFormControl-root{
			margin-top: 20px;
			width: 220px;

			.MuiInput-input{
				border: 1px solid #dfdfe0;
				padding: 6px 10px;
				border-radius: 4px;
				height: 29px;
			}

			.MuiInput-underline:after{
				border-bottom: unset;
			}
		}
		
	
}
.coulmn-icon{
	display: inline-block;
	width: 25px;
	height: 25px;
	background-color: #bcc9d9;
	border-radius: 4px;
	text-align: center;
	background-size: 25px;
}

.btn-add-tenant{
    background-image: linear-gradient(#2799f2, #0C77E3);
    padding: 0px 25px;
    border-radius: 2px;
    color: white;
    line-height: 34px;
    position: relative;
    top: 12px;
    cursor: pointer;
    display: inline-block;
    float: right;
    height: 34px;
    font-size: 11px;
    font-family: nunito-black;
    text-transform: uppercase;
}
.one-time-heading{
	font-family: 'nunito-semiBold';
    color: #000000;
	margin-bottom: 20px;
	font-size: 16px;
}
.report-menu-icon{
    display: inline-block;
}
.down-report-name{
	display: inline-block;
}
.down-report-icon{
	-webkit-mask-image: url('../../../image/folder.png');
	-webkit-mask-size: 17px;
    -webkit-mask-repeat: no-repeat;
    background-color: #959595;
    width: 17px;
    height: 17px;
    display: inline-block;
    position: relative;
    top: 3px;
    right: 6px;
}
.report-redio-input-btn{
	cursor: pointer;
	height: 15px;
    width: 15px;
    position: relative;
    top: 3px;
}
.one-tine-report-view{
    padding: 20px;
}
.uploaded-text {
    color: #40A9FF;
    text-decoration: underline;
    cursor: pointer;
}
.report-label-input-btn{
	left: 10px;
	position: relative;
	top: -1px;
}

.export-box{
	.export-input-box{
		padding: 0 10px
	}

}
.activity-progress{
	width: 100%;
    height: 8px;
}

.report-export-box{

	.ant-calendar-picker{
		width: 100% !important;
	}

	.ant-calendar-picker-input.ant-input {
			line-height: 1.5;
			height: 40px;
			margin-bottom: 16px;
	}
	.ant-calendar-picker-icon{
			top: 20px;
	}
	.from-left-col{
		margin-bottom: 4px;
		position: relative;
			top: -15px;
	}
	.label {
			display: inline-block;
			bottom: -8px;
			position: relative;
	}
	.txt-customer {
		color: #151515;
		margin-bottom: 2px !important;
		font-size: 13px;
	}
	.ant-select-selection__rendered{
		min-height: 35px;
	}
	.export-input-box{
		padding: 0 10px
	}
	

}
.error > div > input{
	border: 1px solid red

}
.unerror > div > input{
	margin-bottom: 0 !important;

}
.helper-text{
    color: #f44336;
    font-size: 0.80rem;
    margin-left: 14px;
		margin-right: 14px;
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
}
.adHoc-report-container{
	padding: 20px 30px;
	.table-box{
		margin-top: 20px;
		padding-left: 25px;
	}
}
.endpoint-report-view{
	.ant-picker{
		height: 40px;
		width: 100%;
	}
}
